.About {
  padding: 5rem 2rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, fit-content);
  grid-template-areas: "titulo" "logo""texto";
}
.About__Title {
  grid-area: titulo;
  font-size: 1.5rem;
}
.About__Image {
  grid-area: logo
}
.About__Text{
  grid-area: texto
}
@media screen and (min-width:64em){
  .About {
    width: 80%;
    min-height: 70vh;
    margin: 0 auto;
    grid-template-columns: 50% 50%;
    grid-template-rows: repeat(2, fit-content);
    grid-template-areas: "logo titulo""logo texto";
  }
  .About__Title {
    display: block;
    margin: 2rem;
    padding: 2rem;
  }
  .About__Image{
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}