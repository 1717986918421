@import url(https://use.fontawesome.com/releases/v5.1.0/css/all.css);

.hamburguer {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, -webkit-filter;
  transition-property: opacity, filter;
  transition-property: opacity, filter, -webkit-filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}
.hamburguer:hover{ opacity: 0.7; }
.hamburguer-box{
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative;
}
.hamburguer:focus{
  outline: 0;
  border: 0;
}
.hamburguer-inner, 
.hamburguer-inner::after, 
.hamburguer-inner::before{
  width: 40px;
  height: 4px;
  background-color: #fff;
  border-radius: 4px;
  position: absolute;
  transition: -webkit-transform 0.15s ease;
  transition: transform 0.15s ease;
  transition: transform 0.15s ease, -webkit-transform 0.15s ease;
}
.hamburguer-inner::before, 
.hamburguer-inner::after{
  content: "";
  display: block;
}
.hamburguer-inner::before{ top: -10px; }
.hamburguer-inner::after{ bottom: -10px; }
.u-afterFixed { padding-top: 4rem; }
.Header {
  position: fixed;
  z-index: 998;
  width: 100%;
  height: 5rem;
  transition: all .3s ease-out;
  background: rgba(22,121,209, .8);
}
.Header.is-active { background: rgba(22,121,209, .95); }
.Header-container {
  margin: 0 auto;
  max-width: 1200px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 4rem, auto;
  grid-template-areas: 'hamburguer logo .' 'panel panel panel';
}
.Logo {
  /* opacity: 0; */
  z-index: 999;
  grid-area: logo;
  justify-self: center;
  transition: all .3s ease-out;
}
.Logo-link{
  display: block;
  width: 3rem;
  height: 3rem;
  background-size: 3rem;
  background-repeat: no-repeat;
  background-position: center;
  text-indent: -9999px;
}
.Logo.is-active{
  transition: all .3s ease-in;
  opacity: 1;
}
.Panel {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  overflow-y: auto;
  grid-area: panel;
  background-color: rgb(22,121,209);
  transition: -webkit-transform .3s ease-out;
  transition: transform .3s ease-out;
  transition: transform .3s ease-out, -webkit-transform .3s ease-out;
  -webkit-transform: translateX(-150vw);
          transform: translateX(-150vw);
}
.Panel.is-active { -webkit-transform: translateY(0); transform: translateY(0); }
.Panel-button {
  padding: 1rem 0;
  z-index: 999;
  grid-area: hamburguer;
  justify-self: start;
}
.Menu{ margin-top: calc(4rem + 2rem); }
.Menu-listItem {
  padding: 0;
  display: flex;
  flex-direction: column;
  text-align: center;
  list-style-type: none;
  list-style: none;
}
.Menu-link {
  display: block;
  padding: 1rem;
  font-weight: bold;
  font-size: 1.5rem;
  color: #ffffff;
  text-decoration: none;
  transition: all .5s ease-out;
}
.Menu-link:hover{
  color: #1679d1;
  background-color: #fff;
}
@media screen and (min-width: 64em) {
  .Header-container{
    grid-template-columns: auto 1fr;
    grid-template-rows: 4rem;
    grid-template-areas: 'logo panel';
  }
  .Logo { justify-self: start; }
  .Panel{
    position: static;
    overflow-y: visible;
    -webkit-transform: translate(0,0);
            transform: translate(0,0);
    background-color: transparent;
  }
  .Panel-button { display: none }
  .Menu{ margin-top: 0; }
  .Menu-listItem{
    flex-direction: row;
    justify-content: flex-end;
  }
  .Menu-link:hover{
    border-top: medium solid #FFF;
    background-color: transparent;
  }
}
.Poster {
  width: 100%;
  min-height: 90vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed, scroll;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Poster-title{
  padding: 0 3vh;
  text-align: center;
  color: #fff;
}
.Poster-title > img {
  width: 90%;
  margin: auto;
}
@media screen and (min-width: 768px) {
  .Poster-title > img { width: 60%; }
}
@media screen and (min-width: 1024px){  
  .Poster-title > img { width: 40%; }
}
.Poster--filter {
  width: 100%;
  background-color: rgba(22,121,209,.5);
  height: 100%;
  min-height: inherit;
  height: inherit;
}
.About {
  padding: 5rem 2rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, -webkit-fit-content);
  grid-template-rows: repeat(3, fit-content);
  grid-template-areas: "titulo" "logo""texto";
}
.About__Title {
  grid-area: titulo;
  font-size: 1.5rem;
}
.About__Image {
  grid-area: logo
}
.About__Text{
  grid-area: texto
}
@media screen and (min-width:64em){
  .About {
    width: 80%;
    min-height: 70vh;
    margin: 0 auto;
    grid-template-columns: 50% 50%;
    grid-template-rows: repeat(2, -webkit-fit-content);
    grid-template-rows: repeat(2, fit-content);
    grid-template-areas: "logo titulo""logo texto";
  }
  .About__Title {
    display: block;
    margin: 2rem;
    padding: 2rem;
  }
  .About__Image{
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.Service {
  min-height: auto;
  width: 100vw;
}
.Service__Title {
  padding-top: 6rem;
  padding-bottom: 2rem;
  font-size: 3rem;
  color: #FFF;
}
.Service__Items {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  justify-items: center;
  margin: 2rem 0;
  padding-bottom: 7rem;
}
.Service__Items h3 {
  font-size: 5vh;
  margin: 0 auto;
  color: #fff;
}
.Service__Item {
  padding: 2rem 1rem;
  background-color: rgba(0,0,0,.3);
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  width: 80%;
}
.Service__Item-title {
  color: #fff;
  font-size: 2.5rem;
  padding-bottom: 1rem;
  grid-area: title;
}
.Service__Item-text {
  padding-top: 1rem;
  color: #eee;
  grid-area: text;
}
.Service__Item-link {
  color: #fff;
  grid-area: link;
}
.Service__Item-figure {
  grid-area: img;
}
.Service__Item:hover {
  background-color: rgba(255,255,255,.9);
  transition: all .5s ease-out;
}
.Service__Item:hover .Service__Item-title { color: #1679d1 }
.Service__Item:hover .Service__Item-text { 
  color: #555; 
  display: block; 
}
.Service__Item:hover .Service__Item-text { color: #1679d1 }
    
@media screen and (min-width: 64em) {
  .Service__Items{
    grid-template-columns: repeat(4, 1fr);
    width: 90%;
    margin: 0 auto

  }
  .Service__Item {
    width: 95%;
  }
}
.Gallery {
  padding: 6rem 0;
  min-height: 100vh;
}
.Gallery__Title {
  font-size: 3rem;
  margin-bottom: 2.5rem;
}
.Gallery__Images {
  display: grid;
  grid-template-columns: repeat(2,1fr);
  justify-items: center;
}
.Gallery__Image {
  overflow: hidden;
  height: 100px;
  margin: 0;
}
.Gallery__Image img {
  size: cover;
}
.Gallery__Image img:hover {
  transition: all .3s ease;
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
}
@media screen and (min-width: 375px) {
  .Gallery__Image { height: 120px }
}
@media screen and (min-width: 425px) {
  .Gallery__Image { height: 140px }
}
@media screen and (min-width: 768px) {
  .Gallery__Image { height: 250px }
}
@media screen and (min-width: 1024px) {
  .Gallery__Images {
    width: 90%;
    margin: 0 auto;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
  }
  .Gallery__Image { height: 200px }
}
@media screen and (min-width: 1440px) {
  .Gallery__Images { grid-template-columns: repeat(4, 1fr) }
}
@media screen and (min-width: 2560px) {
  .Gallery__Image { height: 380px }
}
    

  

.Contact { min-height: 100vh; width: 100vw; }
.Contact--container {
  display: grid;
  grid-template-areas: "title""form""map";
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3,-webkit-fit-content);
  grid-template-rows: repeat(3,fit-content);
  width: 100vw;
}
.Contact__Title {
  grid-area: title;
  padding-top: 6rem;
  color: #ffffff;
  font-size: 3rem;
  padding-bottom: 2rem;
}
.Contact__Form { grid-area: form; }
.Contact__Form-social {
  display: flex;
  justify-content: space-around;
  padding: 1rem;
}
.Contact__Form-social li { list-style: none; }
.Contact__Form-date { 
  margin: 2rem 0; 
  padding: 0;
}
.Contact__Form-date li {
  list-style: none;
  margin: .5rem;
}
.Contact__Form-date li a {
  text-decoration: none;
}
.Contact__Form-date p { color: #fff; }
.Contact__Form input, 
.Contact__Form textarea {
  width: 80%;
  padding: .5rem;
  margin: .5rem;
  resize: none;
}
.Contact__Form input[type="submit"] {
  width: 70%;
  background: #FFF;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  padding: 1rem;
  color: #1679D1;
  font-size: 1.5rem;
}
.Contact__Map {
  grid-area: map;
  overflow: hidden;
  height: 30vh;
  width: 100%;
}
.Contact__Map iframe {
  height: inherit;
  width: inherit;
  opacity: .8;
  border: 0;
}
@media screen and (min-width: 64em) {
  .Contact--container {
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas: "map title title""map form form";
  }
  .Contact__Map {
    height: 100%;
  }
}
.Footer {
  background-color: #1679D1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 2rem;
}
.Footer > p {
  color: #fff
}
.Footer span {
  color: #ccc;
  font-size: .8rem;
}
.Footer span a { text-decoration: none }
.Footer span a:hover { color: #999 ; }
/* Fuentes */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v10/SKK6Nusyv8QPNMtI4j9J2yEAvth_LlrfE80CYdSH47w.woff2) format('woff2');
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v10/gFXtEMCp1m_YzxsBpKl68iEAvth_LlrfE80CYdSH47w.woff2) format('woff2');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v10/zhcz-_WihjSQC0oHJ9TCYPk_vArhqVIZ0nv9q090hN8.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}
/* vietnamese */
@font-face{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: local('Montserrat Bold'), local('Montserrat-Bold'), url(https://fonts.gstatic.com/s/montserrat/v10/IQHow_FEYlDC4Gzy_m8fcjh33M2A-6X0bdu871ruAGs.woff2) format('woff2');
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: local('Montserrat Bold'), local('Montserrat-Bold'), url(https://fonts.gstatic.com/s/montserrat/v10/IQHow_FEYlDC4Gzy_m8fchHJTnCUrjaAm2S9z52xC3Y.woff2) format('woff2');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: local('Montserrat Bold'), local('Montserrat-Bold'), url(https://fonts.gstatic.com/s/montserrat/v10/IQHow_FEYlDC4Gzy_m8fcoWiMMZ7xLd792ULpGE4W_Y.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}
/* latin */
@font-face {
  font-family: 'Permanent Marker';
  font-style: normal;
  font-weight: 400;
  src: local('Permanent Marker'), local('PermanentMarker'), url(https://fonts.gstatic.com/s/permanentmarker/v5/9vYsg5VgPHKK8SXYbf3sMsW72xVeg1938eUHStY_AJ4.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}
/*  BASIC RESET */
html {
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
}
*,
*:after
*:before{
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}
body{
  margin: 0;
  padding: 0;
  min-height: 100vh;
  background: #FFF;
  text-align: center;
}
a{
  color: #ffffff;
  transition: all .3s ease-out;
}
a:hover{
  color: #1679D1;
}
img{
  display: block;
  max-width: 100%;
  height: auto;
}
p{
  padding: .5rem;
  color: #444;
}
h1, h2, h3, h4, h5, h6{
  color: #1679d1;
}
