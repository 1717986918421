.Contact { min-height: 100vh; width: 100vw; }
.Contact--container {
  display: grid;
  grid-template-areas: "title""form""map";
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3,fit-content);
  width: 100vw;
}
.Contact__Title {
  grid-area: title;
  padding-top: 6rem;
  color: #ffffff;
  font-size: 3rem;
  padding-bottom: 2rem;
}
.Contact__Form { grid-area: form; }
.Contact__Form-social {
  display: flex;
  justify-content: space-around;
  padding: 1rem;
}
.Contact__Form-social li { list-style: none; }
.Contact__Form-date { 
  margin: 2rem 0; 
  padding: 0;
}
.Contact__Form-date li {
  list-style: none;
  margin: .5rem;
}
.Contact__Form-date li a {
  text-decoration: none;
}
.Contact__Form-date p { color: #fff; }
.Contact__Form input, 
.Contact__Form textarea {
  width: 80%;
  padding: .5rem;
  margin: .5rem;
  resize: none;
}
.Contact__Form input[type="submit"] {
  width: 70%;
  background: #FFF;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  padding: 1rem;
  color: #1679D1;
  font-size: 1.5rem;
}
.Contact__Map {
  grid-area: map;
  overflow: hidden;
  height: 30vh;
  width: 100%;
}
.Contact__Map iframe {
  height: inherit;
  width: inherit;
  opacity: .8;
  border: 0;
}
@media screen and (min-width: 64em) {
  .Contact--container {
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas: "map title title""map form form";
  }
  .Contact__Map {
    height: 100%;
  }
}