.Poster {
  width: 100%;
  min-height: 90vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed, scroll;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Poster-title{
  padding: 0 3vh;
  text-align: center;
  color: #fff;
}
.Poster-title > img {
  width: 90%;
  margin: auto;
}
@media screen and (min-width: 768px) {
  .Poster-title > img { width: 60%; }
}
@media screen and (min-width: 1024px){  
  .Poster-title > img { width: 40%; }
}
.Poster--filter {
  width: 100%;
  background-color: rgba(22,121,209,.5);
  height: 100%;
  min-height: inherit;
  height: inherit;
}