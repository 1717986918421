.Service {
  min-height: auto;
  width: 100vw;
}
.Service__Title {
  padding-top: 6rem;
  padding-bottom: 2rem;
  font-size: 3rem;
  color: #FFF;
}
.Service__Items {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  justify-items: center;
  margin: 2rem 0;
  padding-bottom: 7rem;
}
.Service__Items h3 {
  font-size: 5vh;
  margin: 0 auto;
  color: #fff;
}
.Service__Item {
  padding: 2rem 1rem;
  background-color: rgba(0,0,0,.3);
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  width: 80%;
}
.Service__Item-title {
  color: #fff;
  font-size: 2.5rem;
  padding-bottom: 1rem;
  grid-area: title;
}
.Service__Item-text {
  padding-top: 1rem;
  color: #eee;
  grid-area: text;
}
.Service__Item-link {
  color: #fff;
  grid-area: link;
}
.Service__Item-figure {
  grid-area: img;
}
.Service__Item:hover {
  background-color: rgba(255,255,255,.9);
  transition: all .5s ease-out;
}
.Service__Item:hover .Service__Item-title { color: #1679d1 }
.Service__Item:hover .Service__Item-text { 
  color: #555; 
  display: block; 
}
.Service__Item:hover .Service__Item-text { color: #1679d1 }
    
@media screen and (min-width: 64em) {
  .Service__Items{
    grid-template-columns: repeat(4, 1fr);
    width: 90%;
    margin: 0 auto

  }
  .Service__Item {
    width: 95%;
  }
}