.Gallery {
  padding: 6rem 0;
  min-height: 100vh;
}
.Gallery__Title {
  font-size: 3rem;
  margin-bottom: 2.5rem;
}
.Gallery__Images {
  display: grid;
  grid-template-columns: repeat(2,1fr);
  justify-items: center;
}
.Gallery__Image {
  overflow: hidden;
  height: 100px;
  margin: 0;
}
.Gallery__Image img {
  size: cover;
}
.Gallery__Image img:hover {
  transition: all .3s ease;
  transform: scale(1.5);
}
@media screen and (min-width: 375px) {
  .Gallery__Image { height: 120px }
}
@media screen and (min-width: 425px) {
  .Gallery__Image { height: 140px }
}
@media screen and (min-width: 768px) {
  .Gallery__Image { height: 250px }
}
@media screen and (min-width: 1024px) {
  .Gallery__Images {
    width: 90%;
    margin: 0 auto;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
  }
  .Gallery__Image { height: 200px }
}
@media screen and (min-width: 1440px) {
  .Gallery__Images { grid-template-columns: repeat(4, 1fr) }
}
@media screen and (min-width: 2560px) {
  .Gallery__Image { height: 380px }
}
    

  
