.hamburguer {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}
.hamburguer:hover{ opacity: 0.7; }
.hamburguer-box{
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative;
}
.hamburguer:focus{
  outline: 0;
  border: 0;
}
.hamburguer-inner, 
.hamburguer-inner::after, 
.hamburguer-inner::before{
  width: 40px;
  height: 4px;
  background-color: #fff;
  border-radius: 4px;
  position: absolute;
  transition: transform 0.15s ease;
}
.hamburguer-inner::before, 
.hamburguer-inner::after{
  content: "";
  display: block;
}
.hamburguer-inner::before{ top: -10px; }
.hamburguer-inner::after{ bottom: -10px; }
.u-afterFixed { padding-top: 4rem; }
.Header {
  position: fixed;
  z-index: 998;
  width: 100%;
  height: 5rem;
  transition: all .3s ease-out;
  background: rgba(22,121,209, .8);
}
.Header.is-active { background: rgba(22,121,209, .95); }
.Header-container {
  margin: 0 auto;
  max-width: 1200px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 4rem, auto;
  grid-template-areas: 'hamburguer logo .' 'panel panel panel';
}
.Logo {
  /* opacity: 0; */
  z-index: 999;
  grid-area: logo;
  justify-self: center;
  transition: all .3s ease-out;
}
.Logo-link{
  display: block;
  width: 3rem;
  height: 3rem;
  background-size: 3rem;
  background-repeat: no-repeat;
  background-position: center;
  text-indent: -9999px;
}
.Logo.is-active{
  transition: all .3s ease-in;
  opacity: 1;
}
.Panel {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  overflow-y: auto;
  grid-area: panel;
  background-color: rgb(22,121,209);
  transition: transform .3s ease-out;
  transform: translateX(-150vw);
}
.Panel.is-active { transform: translateY(0); }
.Panel-button {
  padding: 1rem 0;
  z-index: 999;
  grid-area: hamburguer;
  justify-self: start;
}
.Menu{ margin-top: calc(4rem + 2rem); }
.Menu-listItem {
  padding: 0;
  display: flex;
  flex-direction: column;
  text-align: center;
  list-style-type: none;
  list-style: none;
}
.Menu-link {
  display: block;
  padding: 1rem;
  font-weight: bold;
  font-size: 1.5rem;
  color: #ffffff;
  text-decoration: none;
  transition: all .5s ease-out;
}
.Menu-link:hover{
  color: #1679d1;
  background-color: #fff;
}
@media screen and (min-width: 64em) {
  .Header-container{
    grid-template-columns: auto 1fr;
    grid-template-rows: 4rem;
    grid-template-areas: 'logo panel';
  }
  .Logo { justify-self: start; }
  .Panel{
    position: static;
    overflow-y: visible;
    transform: translate(0,0);
    background-color: transparent;
  }
  .Panel-button { display: none }
  .Menu{ margin-top: 0; }
  .Menu-listItem{
    flex-direction: row;
    justify-content: flex-end;
  }
  .Menu-link:hover{
    border-top: medium solid #FFF;
    background-color: transparent;
  }
}