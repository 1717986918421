.Footer {
  background-color: #1679D1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 2rem;
}
.Footer > p {
  color: #fff
}
.Footer span {
  color: #ccc;
  font-size: .8rem;
}
.Footer span a { text-decoration: none }
.Footer span a:hover { color: #999 ; }